import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Snackbar, 
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Container,
  Typography,
  Radio,
  RadioGroup,
  Box,
} from '@mui/material';
import { Alert } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createQuest } from '../../services/questService';
import { useAuth } from '../../contexts/AuthContext';


const CreateQuest = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [quest, setQuest] = useState({
    name: '',
    description: '',
    category: '',
    estimatedTime: '',
    xp: '',
    type: 'weekly',
    weeklySchedule: [],
    specificDates: [],
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuest(prevQuest => ({
      ...prevQuest,
      [name]: value
    }));
  };

  const handleDayChange = (day) => {
    const updatedSchedule = quest.weeklySchedule.includes(day)
      ? quest.weeklySchedule.filter(d => d !== day)
      : [...quest.weeklySchedule, day];
    setQuest(prevQuest => ({
      ...prevQuest,
      weeklySchedule: updatedSchedule
    }));
    console.log('Updated weeklySchedule:', updatedSchedule); // デバッグ用
  };

  const handleDateChange = (date) => {
    const updatedDates = quest.specificDates.some(d => d.getTime() === date.getTime())
      ? quest.specificDates.filter(d => d.getTime() !== date.getTime())
      : [...quest.specificDates, date];
    setQuest(prevQuest => ({
      ...prevQuest,
      specificDates: updatedDates
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      await createQuest(quest, currentUser.uid);
      setSuccess('クエストが正常に作成されました。');
      navigate('/quests', { state: { refresh: true } });
    } catch (error) {
      setError(`クエストの作成に失敗しました: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  if (loading) return <CircularProgress />;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="sm">
        <Typography variant="h4" component="h1" gutterBottom>
          新規クエスト作成
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            label="クエスト名"
            value={quest.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="description"
            label="説明"
            value={quest.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>カテゴリ</InputLabel>
            <Select
              name="category"
              value={quest.category}
              onChange={handleChange}
              required
            >
              <MenuItem value="言語学習">言語学習</MenuItem>
              <MenuItem value="家事">家事</MenuItem>
              <MenuItem value="健康">健康</MenuItem>
              <MenuItem value="スキルアップ">スキルアップ</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="estimatedTime"
            label="推定時間（分）"
            type="number"
            value={quest.estimatedTime}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="xp"
            label="獲得XP"
            type="number"
            value={quest.xp}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <FormControl component="fieldset" margin="normal">
            <Typography variant="subtitle1">クエストタイプ</Typography>
            <RadioGroup
              name="type"
              value={quest.type}
              onChange={handleChange}
            >
              <FormControlLabel value="weekly" control={<Radio />} label="週間" />
              <FormControlLabel value="specific" control={<Radio />} label="特定日" />
            </RadioGroup>
          </FormControl>
          {quest.type === 'weekly' && (
            <FormGroup>
              <Typography variant="subtitle1">週間スケジュール</Typography>
              {['日', '月', '火', '水', '木', '金', '土'].map((day, index) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox
                      checked={quest.weeklySchedule.includes(index.toString())}
                      onChange={() => handleDayChange(index.toString())}
                      name={day}
                    />
                  }
                  label={day}
                />
              ))}
            </FormGroup>
          )}
          {quest.type === 'specific' && (
            <Box marginBottom="16px">
              <DatePicker
                label="特定日を選択"
                value={null}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
              {quest.specificDates.map((date, index) => (
                <Typography key={index}>{date.toLocaleDateString()}</Typography>
              ))}
            </Box>
          )}
          {quest.type !== 'specific' && (
            <>
              <DatePicker
                label="開始日"
                value={quest.startDate}
                onChange={(date) => setQuest(prev => ({ ...prev, startDate: date }))}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
              <DatePicker
                label="終了日"
                value={quest.endDate}
                onChange={(date) => setQuest(prev => ({ ...prev, endDate: date }))}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
            </>
          )}
          <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading} sx={{ mt: 2 }}>
            クエストを作成
          </Button>
        </form>
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')}>
          <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
            {success}
          </Alert>
        </Snackbar>
      </Container>
    </LocalizationProvider>
  );
};

export default CreateQuest;