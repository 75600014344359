import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getReward, updateReward } from '../../services/rewardService';
import { TextField, Button, Typography, Container, Box, Input, IconButton, Snackbar, CircularProgress } from '@mui/material';
import { Alert } from '@mui/material';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ImageIcon from '@mui/icons-material/Image';
import EmojiPicker from 'emoji-picker-react';
import { useAuth } from '../../contexts/AuthContext';


const EditReward = () => {
  const { rewardId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [reward, setReward] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [xpCost, setXpCost] = useState('');
  const [icon, setIcon] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchReward = async () => {
      try {
        setLoading(true);
        const fetchedReward = await getReward(rewardId);
        setReward(fetchedReward);
        setName(fetchedReward.name);
        setDescription(fetchedReward.description);
        setXpCost(fetchedReward.xpCost.toString());
        setIcon(fetchedReward.icon || '');
      } catch (error) {
        console.error('Error fetching reward:', error);
        setError('リワードの取得に失敗しました: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchReward();
  }, [rewardId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      if (!currentUser) {
        throw new Error('ユーザーが認証されていません。');
      }
      const updatedRewardData = {
        name,
        description,
        xpCost: Number(xpCost),
        icon: icon || null,
      };
      await updateReward(rewardId, updatedRewardData);
      setSuccess('リワードが更新されました！');
      navigate('/rewards');
    } catch (error) {
      console.error('Error updating reward:', error);
      setError(`リワードの更新に失敗しました: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEmojiClick = (event, emojiObject) => {
    setIcon(emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIcon(file);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!reward) return <Alert severity="info">リワードが見つかりません。</Alert>;

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        リワードを編集
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="リワードの名前"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          multiline
          rows={4}
          id="description"
          label="説明"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="xpCost"
          label="必要XP"
          name="xpCost"
          type="number"
          value={xpCost}
          onChange={(e) => setXpCost(e.target.value)}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
          <Typography variant="body1" sx={{ mr: 2 }}>アイコン: {typeof icon === 'string' ? icon : '画像が選択されました'}</Typography>
          <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <EmojiEmotionsIcon />
          </IconButton>
          <Input
            accept="image/*"
            id="icon-image-upload"
            type="file"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <label htmlFor="icon-image-upload">
            <IconButton component="span">
              <ImageIcon />
            </IconButton>
          </label>
        </Box>
        {showEmojiPicker && (
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          更新
        </Button>
      </Box>
      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')}>
        <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditReward;