import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createReward } from '../../services/rewardService';
import { TextField, Button, Typography, Container, Box, Input, IconButton, Snackbar, CircularProgress } from '@mui/material';
import { Alert } from '@mui/material';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ImageIcon from '@mui/icons-material/Image';
import EmojiPicker from 'emoji-picker-react';
import { useAuth } from '../../contexts/AuthContext'; // AuthContext をインポート

const CreateReward = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // currentUser を取得
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [xpCost, setXpCost] = useState('');
  const [icon, setIcon] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      if (!currentUser) {
        throw new Error('ユーザーが認証されていません。');
      }
      const newRewardData = {
        name,
        description,
        xpCost: Number(xpCost),
        icon: icon || null,
        userId: currentUser.uid, // ユーザーID を追加
      };
      const createdRewardId = await createReward(newRewardData);
      console.log('Created reward with ID:', createdRewardId);
      console.log('Created reward data:', newRewardData);
      setSuccess('ご褒美が作成されました！');
      navigate('/rewards');
    } catch (error) {
      console.error('Error creating reward:', error);
      setError(`ご褒美の作成に失敗しました: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEmojiClick = (event, emojiObject) => {
    setIcon(emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIcon(file);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        新しいご褒美を作成
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="ご褒美の名前"
          name="name"
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          multiline
          rows={4}
          id="description"
          label="説明"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="xpCost"
          label="必要XP"
          name="xpCost"
          type="number"
          value={xpCost}
          onChange={(e) => setXpCost(e.target.value)}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
          <Typography variant="body1" sx={{ mr: 2 }}>アイコン: {typeof icon === 'string' ? icon : '画像が選択されました'}</Typography>
          <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <EmojiEmotionsIcon />
          </IconButton>
          <Input
            accept="image/*"
            id="icon-image-upload"
            type="file"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <label htmlFor="icon-image-upload">
            <IconButton component="span">
              <ImageIcon />
            </IconButton>
          </label>
        </Box>
        {showEmojiPicker && (
          <EmojiPicker onEmojiClick={handleEmojiClick} />
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          作成
        </Button>
      </Box>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')}>
        <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateReward;