// src/components/common/XPDisplay.tsx

import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { getXPBalance } from '../../services/userService';
import { useAuth } from '../../contexts/AuthContext';

const XPDisplay: React.FC = () => {
  const [xp, setXP] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchXP = async () => {
      if (currentUser) {
        const userXP = await getXPBalance(currentUser.uid);
        setXP(userXP);
      }
    };
    fetchXP();
  }, [currentUser]);

  return (
    <Typography variant="h6">
      獲得XP: {xp}
    </Typography>
  );
};

export default XPDisplay;