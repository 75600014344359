import React from 'react'; // Reactライブラリをインポート
import { Grid, Avatar, IconButton } from '@mui/material'; // Material-UIからコンポーネントをインポート

const avatars = [ // アバター画像のリストを定義
  '/avatars/avatar1.png',
  '/avatars/avatar2.png',
  '/avatars/avatar3.png',
  '/avatars/avatar4.png',
  '/avatars/avatar5.png',
];

const AvatarSelector = ({ onSelect, selectedAvatar }) => { // AvatarSelectorコンポーネントを定義
  return (
    <Grid container spacing={2}> 
      {avatars.map((avatar, index) => ( // 各アバター画像をループで生成
        <Grid item key={index}> 
          <IconButton
            onClick={() => onSelect(avatar)} // アバターがクリックされたときにonSelect関数を呼び出す
            sx={{
              border: avatar === selectedAvatar ? '2px solid blue' : 'none', // 選択されたアバターに青い枠線を追加
              padding: 0,
            }}
          >
            <Avatar
              src={avatar} // アバター画像のURLを設定
              alt={`Avatar ${index + 1}`} // 画像の代替テキストを設定
              sx={{ width: 60, height: 60 }} // アバターのサイズを設定
            />
          </IconButton>
        </Grid>
      ))}
    </Grid>
  );
};

export default AvatarSelector; // AvatarSelectorコンポーネントをエクスポート
