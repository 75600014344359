import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Grid,
  Container,
  Box,
  Chip,
  Dialog
} from '@mui/material';
import { completeQuest, deleteQuest } from '../../services/questService';
import { useAuth } from '../../contexts/AuthContext';
import { Quest } from '../../types/Quest';
import QuestClearScreen from './QuestClearScreen';

interface QuestItemProps {
  quest: Quest;
  onQuestUpdated: () => void;
}

const QuestItem: React.FC<QuestItemProps> = ({ quest, onQuestUpdated }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [showClearScreen, setShowClearScreen] = useState(false);

  const handleComplete = async () => {
    try {
      if (currentUser) {
        await completeQuest(quest.id, currentUser.uid);
        setShowClearScreen(true);
      }
    } catch (error) {
      console.error("Error completing quest: ", error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('このクエストを削除してもよろしいですか？')) {
      try {
        await deleteQuest(quest.id);
        onQuestUpdated();
        navigate('/quests');
      } catch (error) {
        console.error("Error deleting quest: ", error);
      }
    }
  };

  const handleUpdate = () => {
    navigate(`/quests/${quest.id}/edit`);
  };

  const handleCloseClearScreen = () => {
    setShowClearScreen(false);
    onQuestUpdated();
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{ backgroundColor: '#8BC34A', color: 'white', padding: '10px' }}>
        クエスト詳細
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => navigate('/quests/create')}
        style={{ marginBottom: '20px' }}
      >
        新規クエスト作成
      </Button>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            {quest.name}
          </Typography>
          <Typography color="text.secondary">
            {quest.type === 'weekly' ? '週間クエスト' : `期限: ${quest.deadline}`}
          </Typography>
          <Typography variant="body2">
            説明: {quest.description}
          </Typography>
          <Typography>
            XP: {quest.xp} ⭐
          </Typography>
          <Chip
            label={quest.status === 'completed' ? '完了' : '未完了'}
            color={quest.status === 'completed' ? 'success' : 'error'}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          />
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleComplete}
                  disabled={quest.status === 'completed'}
                >
                  {quest.status === 'completed' ? '完了済み' : 'クエスト完了'}
                </Button>
              </Grid>
              <Grid item>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={handleUpdate}
                >
                  更新
                </Button>
              </Grid>
              <Grid item>
                <Button 
                  variant="outlined" 
                  color="error" 
                  onClick={handleDelete}
                >
                  削除
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={showClearScreen} onClose={handleCloseClearScreen} fullWidth maxWidth="sm">
        <QuestClearScreen quest={quest} onClose={handleCloseClearScreen} />
      </Dialog>
    </Container>
  );
};

export default QuestItem;