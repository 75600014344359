import React, { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
  Grid,
  FormControlLabel,
  Switch,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { getUserRewards } from '../../services/rewardService';
import { useAuth } from '../../contexts/AuthContext';
import RewardItem from './RewardItem';
import { Reward } from '../../types/Reward';
import { getUserXP } from '../../services/userService';

const RewardList: React.FC = () => {
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [filteredRewards, setFilteredRewards] = useState<Reward[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showClaimed, setShowClaimed] = useState(false);
  const { currentUser } = useAuth();
  const [currentXP, setCurrentXP] = useState(0);

  const fetchRewards = useCallback(async () => {
    if (currentUser) {
      try {
        setLoading(true);
        const [fetchedRewards, userXP] = await Promise.all([
          getUserRewards(currentUser.uid),
          getUserXP(currentUser.uid)
        ]);
        console.log('Fetched rewards:', fetchedRewards);
        setRewards(fetchedRewards);
        setFilteredRewards(fetchedRewards);
        setCurrentXP(userXP);
        setError(null);
      } catch (error) {
        console.error('Error fetching rewards or XP:', error);
        setError('データの取得中にエラーが発生しました。');
      } finally {
        setLoading(false);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    fetchRewards();
  }, [fetchRewards]);

  useEffect(() => {
    const filtered = rewards.filter(reward =>
      reward.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (showClaimed || !reward.claimed)
    );
    setFilteredRewards(filtered);
  }, [rewards, searchTerm, showClaimed]);

  const handleRewardUpdated = useCallback(() => {
    fetchRewards();
  }, [fetchRewards]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{ backgroundColor: '#8BC34A', color: 'white', padding: '10px' }}>
        ご褒美リスト
      </Typography>
      <Typography variant="h6" gutterBottom>
        現在のXP: {currentXP}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="検索..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Switch
                checked={showClaimed}
                onChange={(e) => setShowClaimed(e.target.checked)}
                color="primary"
              />
            }
            label="獲得済みのご褒美を表示"
          />
        </Grid>
      </Grid>
      <Button
        component={RouterLink}
        to="/rewards/create"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        fullWidth
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        新規ご褒美作成
      </Button>
      {filteredRewards.length > 0 ? (
        filteredRewards.map((reward) => (
          <RewardItem 
            key={reward.id} 
            reward={reward} 
            onRewardUpdated={handleRewardUpdated}
            currentXP={currentXP}
          />
        ))
      ) : (
        <Typography variant="body1">
          {searchTerm || showClaimed
            ? '条件に一致するご褒美がありません。'
            : 'ご褒美がまだありません。新しいご褒美を作成してください。'}
        </Typography>
      )}
    </Container>
  );
};

export default RewardList;