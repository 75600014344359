import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Container, 
  Grid, 
  Paper, 
  Typography, 
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { 
  List as ListIcon, 
  Add as AddIcon, 
  EmojiEvents as RewardIcon, 
  AddCircle as AddRewardIcon 
} from '@mui/icons-material';

const HomePage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItems = [
    { title: 'クエスト一覧', icon: <ListIcon fontSize="large" />, path: '/quests' },
    { title: 'クエスト作成', icon: <AddIcon fontSize="large" />, path: '/quests/create' },
    { title: 'ご褒美一覧', icon: <RewardIcon fontSize="large" />, path: '/rewards' },
    { title: 'ご褒美作成', icon: <AddRewardIcon fontSize="large" />, path: '/rewards/create' },
  ];

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        ホーム
      </Typography>
      <Grid container spacing={2}>
        {menuItems.map((item, index) => (
          <Grid item xs={6} key={index}>
            <Paper 
              component={Link} 
              to={item.path}
              sx={{
                height: isSmallScreen ? 120 : 160,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'inherit',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 3,
                },
              }}
            >
              <Box sx={{ color: theme.palette.primary.main }}>
                {item.icon}
              </Box>
              <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                {item.title}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HomePage;