import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

interface ProgressBarProps {
  currentXP: number;
  requiredXP: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentXP, requiredXP }) => {
  const progress = Math.min((currentXP / requiredXP) * 100, 100);
  const remainingXP = Math.max(requiredXP - currentXP, 0);

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body2" color="text.secondary">
          進捗: {progress.toFixed(0)}%
        </Typography>
        <Typography variant="body2" color="text.secondary">
          残り: {remainingXP} XP
        </Typography>
      </Box>
      <LinearProgress variant="determinate" value={progress} sx={{ mt: 1 }} />
    </Box>
  );
};

export default ProgressBar;