// src/components/common/BottomNav.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonIcon from '@mui/icons-material/Person';

const BottomNav: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box position="fixed" bottom={0} left={0} right={0}>
      <BottomNavigation showLabels>
        <BottomNavigationAction 
          label="ホーム" 
          icon={<HomeIcon />} 
          onClick={() => navigate('/')} 
        />
        <BottomNavigationAction 
          label="クエスト" 
          icon={<ListIcon />} 
          onClick={() => navigate('/quests')} 
        />
        <BottomNavigationAction 
          label="ごほうび" 
          icon={<EmojiEventsIcon />} 
          onClick={() => navigate('/rewards')} 
        />
        <BottomNavigationAction 
          label="プロフィール" 
          icon={<PersonIcon />} 
          onClick={() => navigate('/dashboard')} 
        />
      </BottomNavigation>
    </Box>
  );
};

export default BottomNav;