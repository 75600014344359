import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Snackbar, 
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Container,
  Box,
  Grid
} from '@mui/material';
import { Alert } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getQuest, updateQuest, deleteQuest } from '../../services/questService';
import { useAuth } from '../../contexts/AuthContext';

const EditQuest = () => {
  const { questId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [quest, setQuest] = useState({
    name: '',
    description: '',
    category: '',
    type: '',
    estimatedTime: '',
    xp: '',
    weeklySchedule: [],
    specificDates: '',
    startDate: null,
    endDate: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  
  useEffect(() => {
    const fetchQuest = async () => {
      try {
        const fetchedQuest = await getQuest(questId);
        if (fetchedQuest.userId !== currentUser.uid) {
          throw new Error("You don't have permission to edit this quest.");
        }
        setQuest({
          ...fetchedQuest,
          weeklySchedule: fetchedQuest.weeklySchedule ? fetchedQuest.weeklySchedule.map(day => day.toString()) : [],
          specificDates: fetchedQuest.specificDates 
            ? fetchedQuest.specificDates.map(date => date.toDate().toISOString().split('T')[0]) 
            : '',
          startDate: fetchedQuest.startDate ? fetchedQuest.startDate.toDate() : null,
          endDate: fetchedQuest.endDate ? fetchedQuest.endDate.toDate() : null,
        });
      } catch (error) {
        console.error('Error fetching quest:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchQuest();
  }, [questId, currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuest(prevQuest => ({
      ...prevQuest,
      [name]: value
    }));
  };

  const handleDayChange = (day) => {
    const updatedSchedule = quest.weeklySchedule.includes(day)
      ? quest.weeklySchedule.filter(d => d !== day)
      : [...quest.weeklySchedule, day];
    setQuest(prevQuest => ({
      ...prevQuest,
      weeklySchedule: updatedSchedule
    }));
  };

  const handleDateChange = (field, date) => {
    setQuest(prevQuest => ({
      ...prevQuest,
      [field]: date
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const updatedQuest = {
        ...quest,
        specificDates: quest.type === 'specific' && quest.specificDates
          ? [new Date(quest.specificDates)]
          : [],
        startDate: quest.startDate,
        endDate: quest.endDate,
        estimatedTime: Number(quest.estimatedTime),
        xp: Number(quest.xp)
      };
      await updateQuest(questId, updatedQuest);
      setSuccess('クエストが正常に更新されました。');
      navigate('/quests');
    } catch (error) {
      console.error('Error updating quest:', error);
      setError(`クエストの更新に失敗しました: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('このクエストを削除してもよろしいですか？')) {
      setLoading(true);
      setError('');
      try {
        await deleteQuest(questId);
        navigate('/quests');
      } catch (error) {
        console.error('Error deleting quest:', error);
        setError(`クエストの削除に失敗しました: ${error.message}`);
        setLoading(false);
      }
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="sm">
        <Typography variant="h4" gutterBottom style={{ backgroundColor: '#8BC34A', color: 'white', padding: '10px' }}>
          クエスト編集
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            name="name"
            label="クエスト名"
            value={quest.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            name="description"
            label="説明"
            value={quest.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>カテゴリ</InputLabel>
            <Select
              name="category"
              value={quest.category}
              onChange={handleChange}
              required
            >
              <MenuItem value="言語学習">言語学習</MenuItem>
              <MenuItem value="家事">家事</MenuItem>
              <MenuItem value="健康">健康</MenuItem>
              <MenuItem value="スキルアップ">スキルアップ</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>タイプ</InputLabel>
            <Select
              name="type"
              value={quest.type}
              onChange={handleChange}
              required
            >
              <MenuItem value="weekly">週間</MenuItem>
              <MenuItem value="specific">特定日</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name="estimatedTime"
            label="推定時間（分）"
            type="number"
            value={quest.estimatedTime}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="xp"
            label="獲得XP"
            type="number"
            value={quest.xp}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          {quest.type === 'weekly' && (
            <FormGroup>
              <Typography variant="subtitle1">週間スケジュール</Typography>
              {['日', '月', '火', '水', '木', '金', '土'].map((day, index) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox
                      checked={quest.weeklySchedule.includes(index.toString())}
                      onChange={() => handleDayChange(index.toString())}
                      name={day}
                    />
                  }
                  label={day}
                />
              ))}
            </FormGroup>
          )}
          {quest.type === 'specific' && (
            <TextField
              name="specificDates"
              label="特定の日付"
              type="date"
              value={quest.specificDates}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <DatePicker
          label="開始日"
          value={quest.startDate}
          onChange={(date) => handleDateChange('startDate', date)}
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
          <DatePicker
            label="終了日"
            value={quest.endDate}
            onChange={(date) => handleDateChange('endDate', date)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
                  更新
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={handleDelete} variant="contained" color="secondary" fullWidth disabled={loading}>
                  削除
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
        <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')}>
          <Alert onClose={() => setSuccess('')} severity="success" sx={{ width: '100%' }}>
            {success}
          </Alert>
        </Snackbar>
      </Container>
    </LocalizationProvider>
  );
};

export default EditQuest;