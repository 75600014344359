import React, { createContext, useState, useEffect, useContext } from 'react';
import { getXPBalance } from '../services/userService';
import { useAuth } from './AuthContext';

const XPContext = createContext();

export const useXP = () => useContext(XPContext);

export const XPProvider = ({ children }) => {
  const [xp, setXP] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      const fetchXP = async () => {
        const balance = await getXPBalance(currentUser.uid);
        setXP(balance);
      };
      fetchXP();
    }
  }, [currentUser]);

  const updateXP = (newXP) => {
    setXP(newXP);
  };

  return (
    <XPContext.Provider value={{ xp, updateXP }}>
      {children}
    </XPContext.Provider>
  );
};