// src/components/profile/BadgeDisplay.js
import React, { useState, useEffect } from 'react';
import { Grid, Avatar, Typography, Tooltip } from '@mui/material';
import { getUserBadges } from '../../services/badgeService';
import { useAuth } from '../../contexts/AuthContext';

const BadgeDisplay = () => {
  const [badges, setBadges] = useState([]);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchBadges = async () => {
      if (currentUser && currentUser.uid) {
        try {
          const userBadges = await getUserBadges(currentUser.uid);
          setBadges(userBadges);
        } catch (err) {
          console.error('Error fetching badges:', err);
          setError('バッジの取得に失敗しました');
        }
      }
    };
    fetchBadges();
  }, [currentUser]);

  if (!currentUser) {
    return <Typography>ログインしてください</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Grid container spacing={2}>
      {badges.length > 0 ? (
        badges.map((badge) => (
          <Grid item key={badge.id}>
            <Tooltip title={badge.description}>
              <Avatar
                src={badge.imageUrl}
                alt={badge.name}
                style={{ width: 40, height: 40 }}
              />
            </Tooltip>
          </Grid>
        ))
      ) : (
        <Typography>バッジはまだありません</Typography>
      )}
    </Grid>
  );
};

export default BadgeDisplay;