import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { getUserProfile, updateUserProfile } from '../../services/userService';
import AvatarSelector from './AvatarSelector';

const EditProfile = () => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState({
    name: '',
    bio: '',
    avatar: '',
  });

  useEffect(() => {
    const fetchProfile = async () => {
      if (currentUser) {
        const userProfile = await getUserProfile(currentUser.uid);
        if (userProfile) {
          setProfile(userProfile);
        }
      }
    };
    fetchProfile();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleAvatarSelect = (avatar) => {
    setProfile((prev) => ({ ...prev, avatar }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentUser) {
      try {
        await updateUserProfile(currentUser.uid, profile);
        alert('プロフィールが正常に更新されました！');
      } catch (error) {
        console.error('プロフィール更新エラー:', error);
        alert('プロフィールの更新に失敗しました。もう一度お試しください。');
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400, margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        プロフィール編集
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        label="表示名"
        name="name"
        value={profile.name}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="自己紹介"
        name="bio"
        multiline
        rows={4}
        value={profile.bio}
        onChange={handleChange}
      />
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        アバターを選択
      </Typography>
      <AvatarSelector onSelect={handleAvatarSelect} selectedAvatar={profile.avatar} />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
        プロフィールを保存
      </Button>
    </Box>
  );
};

export default EditProfile;