import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const PublicHomePage = () => {
  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Ludibitへようこそ
        </Typography>
        <Typography variant="body1" paragraph>
          タスクを楽しくこなし、ご褒美を獲得しよう！
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Button component={Link} to="/login" variant="contained" color="primary" sx={{ mr: 2 }}>
            ログイン
          </Button>
          <Button component={Link} to="/signup" variant="outlined" color="primary">
            新規登録
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PublicHomePage;