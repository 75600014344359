import { db, storage } from '../firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  doc, 
  getDoc,
  updateDoc, 
  arrayUnion, 
  runTransaction,
  where,
  deleteDoc,
  query,
  Timestamp
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Reward, RewardData } from '../types/Reward';
import { subtractXP, getXPBalance } from './userService';

export const createReward = async (rewardData: RewardData): Promise<string> => {
  try {
    let iconUrl = rewardData.icon;
    
    if (rewardData.icon instanceof File) {
      const iconRef = ref(storage, `reward-icons/${Date.now()}_${rewardData.icon.name}`);
      await uploadBytes(iconRef, rewardData.icon);
      iconUrl = await getDownloadURL(iconRef);
    }

    const rewardDataToSave = {
      ...rewardData,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      isCustom: true,
      icon: iconUrl,
      claimed: false,
      claimedBy: []
    };

    const docRef = await addDoc(collection(db, 'rewards'), rewardDataToSave);
    return docRef.id;
  } catch (error) {
    console.error('Error creating reward:', error);
    throw error;
  }
};

export const getRewards = async (): Promise<Reward[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, 'rewards'));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Reward));
  } catch (error) {
    console.error('Error getting rewards:', error);
    throw error;
  }
};

export const getUserRewards = async (userId: string): Promise<Reward[]> => {
  try {
    console.log('Fetching rewards for user ID:', userId);
    const rewardsRef = collection(db, 'rewards');
    const q = query(rewardsRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const rewards = querySnapshot.docs.map(doc => ({ 
      id: doc.id, 
      ...doc.data(),
      claimed: doc.data().claimed || false,
      claimedBy: doc.data().claimedBy || []
    } as Reward));
    console.log('Fetched rewards:', rewards);
    return rewards;
  } catch (error) {
    console.error('Error getting user rewards:', error);
    throw error;
  }
};

export const updateReward = async (rewardId: string, updatedData: Partial<RewardData>): Promise<string> => {
  try {
    const rewardRef = doc(db, 'rewards', rewardId);
    await updateDoc(rewardRef, {
      ...updatedData,
      updatedAt: Timestamp.now()
    });
    return "Reward updated successfully!";
  } catch (error) {
    console.error('Error updating reward:', error);
    throw error;
  }
};

export const deleteReward = async (rewardId: string): Promise<string> => {
  try {
    const rewardRef = doc(db, 'rewards', rewardId);
    await deleteDoc(rewardRef);
    return "Reward deleted successfully!";
  } catch (error) {
    console.error('Error deleting reward:', error);
    throw error;
  }
};

export const getReward = async (rewardId: string): Promise<Reward> => {
  try {
    const rewardDoc = await getDoc(doc(db, 'rewards', rewardId));
    if (rewardDoc.exists()) {
      return { 
        id: rewardDoc.id, 
        ...rewardDoc.data(),
        claimed: rewardDoc.data().claimed || false,
        claimedBy: rewardDoc.data().claimedBy || []
      } as Reward;
    } else {
      throw new Error('リワードが見つかりません。');
    }
  } catch (error) {
    console.error('Error getting reward:', error);
    throw error;
  }
};

export const claimReward = async (rewardId: string, userId: string): Promise<void> => {
  const rewardRef = doc(db, 'rewards', rewardId);

  try {
    await runTransaction(db, async (transaction) => {
      const rewardSnap = await transaction.get(rewardRef);

      if (!rewardSnap.exists()) {
        throw new Error('Reward not found');
      }

      const rewardData = rewardSnap.data() as Reward;
    
      if (rewardData.claimed) {
        throw new Error('This reward has already been claimed');
      }

      const userXP = await getXPBalance(userId);

      if (userXP < rewardData.xpCost) {
        throw new Error('Not enough XP');
      }

      await subtractXP(userId, rewardData.xpCost);
      
      transaction.update(rewardRef, { 
        claimed: true,
        claimedBy: arrayUnion(userId)
      });
    });
  } catch (error) {
    console.error('Error claiming reward:', error);
    throw error;
  }
};