import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Quest } from '../../services/questService';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Card,
  CardContent,
  Grid,
  Chip,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Pagination,
  Select,
  MenuItem,
  Snackbar,
  useMediaQuery,
  useTheme,
  SelectChangeEvent,
  Divider,
  Dialog,

} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { getUserQuests, getTodayQuests, completeQuest } from '../../services/questService';
import { useAuth } from '../../contexts/AuthContext';
import QuestClearScreen from './QuestClearScreen';


const QuestList: React.FC = React.memo(() => {
  const [quests, setQuests] = useState<Quest[]>([]);
  const [todayQuests, setTodayQuests] = useState<Quest[]>([]);
  const [filteredQuests, setFilteredQuests] = useState<Quest[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filter, setFilter] = useState<string>('すべて');
  const { currentUser } = useAuth();
  const [page, setPage] = useState<number>(1);
  const questsPerPage = 10;
  const [sortBy, setSortBy] = useState<string>('deadline');
  const [categoryFilter, setCategoryFilter] = useState<string>('all');
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const [showClearScreen, setShowClearScreen] = useState(false);
  const [clearedQuest, setClearedQuest] = useState<Quest | null>(null);


  const fetchQuests = useCallback(async () => {
    if (!currentUser) {
      setError('ユーザーがログインしていません。');
      return;
    }
    try {
      const fetchedQuests = await getUserQuests(currentUser.uid);
      const fetchedTodayQuests = await getTodayQuests(currentUser.uid);
      setQuests(fetchedQuests);
      setTodayQuests(fetchedTodayQuests);
      setFilteredQuests(fetchedQuests);
    } catch (error) {
      console.error('Failed to fetch quests:', error);
      setError('クエストの取得に失敗しました。');
    }
  }, [currentUser]);

  useEffect(() => {
    fetchQuests();
    const intervalId = setInterval(fetchQuests, 60000); // 1分ごとに更新
  
    return () => clearInterval(intervalId);
  }, [fetchQuests]);

  useEffect(() => {
    if (location.state && 'refresh' in location.state && location.state.refresh) {
      fetchQuests();
    }
  }, [location, fetchQuests]);

  const sortedAndFilteredQuests = useMemo(() => {
    return quests
      .filter(quest => {
        const matchesSearch = quest.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesFilter = 
          filter === 'すべて' ? true :
          filter === '今日のクエスト' ? todayQuests.some(tq => tq.id === quest.id) :
          filter === '完了' ? quest.status === 'completed' :
          filter === '未完了' ? quest.status === 'active' : true;
        const matchesCategory = categoryFilter === 'all' || quest.category === categoryFilter;
        return matchesSearch && matchesFilter && matchesCategory;
      })
      .sort((a, b) => {
        if (sortBy === 'deadline') {
          if (!a.deadline) return 1;
          if (!b.deadline) return -1;
          return a.deadline.toDate().getTime() - b.deadline.toDate().getTime();
        }
        if (sortBy === 'xp') return (b.xp ?? 0) - (a.xp ?? 0);
        return 0;
      });
  }, [quests, todayQuests, searchTerm, filter, categoryFilter, sortBy]);

  useEffect(() => {
    setFilteredQuests(sortedAndFilteredQuests);
  }, [sortedAndFilteredQuests]);

  const handleCompleteQuest = useCallback(async (questId: string) => {
    try {
      if (currentUser) {
        await completeQuest(questId, currentUser.uid);
        const updatedQuest = quests.find(q => q.id === questId);
        if (updatedQuest) {
          setShowClearScreen(true);
          setClearedQuest(updatedQuest);
        }
        await fetchQuests(); // クエストリストを更新
      }
    } catch (error) {
      console.error('Failed to complete quest:', error);
      setError('クエストの完了に失敗しました。');
    }
  }, [fetchQuests, currentUser, quests]);

  const paginatedQuests = filteredQuests.slice((page - 1) * questsPerPage, page * questsPerPage);

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSortBy(event.target.value);
  };

  const handleCategoryFilterChange = (event: SelectChangeEvent<string>) => {
    setCategoryFilter(event.target.value);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleErrorClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  const isQuestCompletedToday = (quest: Quest): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 日付のみを比較するために時間をリセット
  
    // 最後に完了した日付が今日かどうかをチェック
    if (quest.lastCompletedDate) {
      const lastCompletedDate = quest.lastCompletedDate.toDate();
      lastCompletedDate.setHours(0, 0, 0, 0);
      if (lastCompletedDate.getTime() === today.getTime()) {
        return true;
      }
    }
  
    // completedDates 配列内の日付をチェック
    return quest.completedDates.some(date => {
      const completedDate = date.toDate();
      completedDate.setHours(0, 0, 0, 0);
      return completedDate.getTime() === today.getTime();
    });
  };

  const getQuestStatus = (quest: Quest): { label: string; color: "success" | "error" | "default" } => {
    if (isQuestCompletedToday(quest)) {
      return { label: '今日完了', color: 'success' };
    } else if (quest.status === 'completed') {
      return { label: '達成済み', color: 'default' };
    } else {
      return { label: '未完了', color: 'error' };
    }
  };

  if (!currentUser) {
    return <Typography>ログインしてください。</Typography>;
  }

  return (
    <>
    <Container>
      <Typography variant="h4" gutterBottom style={{ backgroundColor: '#8BC34A', color: 'white', padding: '10px' }}>
        クエスト一覧
      </Typography>

      {/* 今日のクエスト */}
      <Typography variant="h5" gutterBottom>今日のクエスト</Typography>
      {todayQuests.length > 0 ? (
        todayQuests.map((quest) => (
          <Card key={quest.id} style={{ marginBottom: '10px' }}>
            <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Typography variant="h6">{quest.name}</Typography>
                  <Typography>{quest.xp ?? 0} ⭐</Typography>
                </Grid>
                <Grid item xs={12} sm={4} container direction="column" alignItems={isMobile ? "flex-start" : "flex-end"}>
                  <Chip
                    label={getQuestStatus(quest).label}
                    color={getQuestStatus(quest).color}
                    style={{ marginBottom: '10px' }}
                  />
                  <Button
                    onClick={() => quest.id && handleCompleteQuest(quest.id)}
                    disabled={isQuestCompletedToday(quest) || quest.status === 'completed'}
                    variant="contained"
                    color="primary"
                    style={{ width: isMobile ? '100%' : 'auto' }}
                  >
                    完了
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography>今日のクエストはありません。</Typography>
      )}

      <Divider style={{ margin: '20px 0' }} />

      {/* 検索バーと絞り込み */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="検索..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{ marginBottom: '20px' }}
      />
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            value={sortBy}
            onChange={handleSortChange}
          >
            <MenuItem value="deadline">期限順</MenuItem>
            <MenuItem value="xp">XP順</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            value={categoryFilter}
            onChange={handleCategoryFilterChange}
          >
            <MenuItem value="all">全カテゴリー</MenuItem>
            <MenuItem value="work">仕事</MenuItem>
            <MenuItem value="personal">個人</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Button
        component={RouterLink}
        to="/quests/create"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        fullWidth
        style={{ marginBottom: '20px' }}
      >
        新規クエスト作成
      </Button>
      <Box 
        display="flex" 
        overflow="auto" 
        mb={2} 
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          scrollbarWidth: 'none'
        }}
      >
        {['すべて', '完了', '未完了'].map((tag) => (
          <Button
            key={tag}
            variant={filter === tag ? "contained" : "outlined"}
            color="primary"
            onClick={() => setFilter(tag)}
            style={{ 
              marginRight: '10px',
              whiteSpace: 'nowrap',
              minWidth: 'max-content'
            }}
          >
            {tag}
          </Button>
        ))}
      </Box>

      {/* クエストリスト */}
      {paginatedQuests.map((quest) => (
        <Card key={quest.id} style={{ marginBottom: '10px' }}>
          <CardContent>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={8}>
                <Typography variant="h6">{quest.name}</Typography>
                <Typography color="textSecondary">
                  {quest.type === 'weekly' ? '週間クエスト' : `期限: ${quest.deadline ? quest.deadline.toDate().toLocaleDateString() : '未設定'}`}
                </Typography>
                <Typography>{quest.xp ?? 0} ⭐</Typography>
              </Grid>
              <Grid item xs={12} sm={4} container direction="column" alignItems={isMobile ? "flex-start" : "flex-end"}>
                <Chip
                  label={getQuestStatus(quest).label}
                  color={getQuestStatus(quest).color}
                  style={{ marginBottom: '10px' }}
                />
                <Button 
                  component={RouterLink} 
                  to={`/quests/${quest.id}`} 
                  variant="outlined" 
                  color="primary"
                  style={{ marginBottom: '10px', width: isMobile ? '100%' : 'auto' }}
                >
                  詳細
                </Button>
                <Button
                  onClick={() => quest.id && handleCompleteQuest(quest.id)}
                  disabled={isQuestCompletedToday(quest) || quest.status === 'completed'}
                  variant="contained"
                  color="primary"
                  style={{ width: isMobile ? '100%' : 'auto' }}
                >
                  完了
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      <Pagination 
        count={Math.ceil(filteredQuests.length / questsPerPage)} 
        page={page} 
        onChange={handlePageChange}
        style={{ marginTop: '20px', marginBottom: '80px' }}
      />
      <Box position="fixed" bottom={0} left={0} right={0}>
        <BottomNavigation showLabels>
          <BottomNavigationAction label="ホーム" icon={<HomeIcon />} onClick={() => navigate('/')} />
          <BottomNavigationAction label="クエスト" icon={<ListIcon />} onClick={() => navigate('/quests')} />
          <BottomNavigationAction label="ごほうび" icon={<EmojiEventsIcon />} onClick={() => navigate('/rewards')} />
          <BottomNavigationAction label="プロフィール" icon={<PersonIcon />} onClick={() => navigate('/profile')} />
        </BottomNavigation>
      </Box>
      <Box position="fixed" right={16} bottom={70}>
        <Button
          component={RouterLink}
          to="/quests/create"
          variant="contained"
          color="primary"
          style={{ borderRadius: '50%', minWidth: '56px', width: '56px', height: '56px' }}
        >
          <AddIcon />
        </Button>
      </Box>
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={handleErrorClose}
        message={error}
      />
    </Container>
    <Dialog open={showClearScreen} onClose={() => setShowClearScreen(false)} fullWidth maxWidth="sm">
    {clearedQuest && <QuestClearScreen quest={clearedQuest} onClose={() => {
      setShowClearScreen(false);
      setClearedQuest(null);
      fetchQuests();
    }} />}
  </Dialog>
  </>
  );
});

export default QuestList;