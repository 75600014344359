// src/components/rewards/RewardDetail.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Button, 
  CircularProgress, 
  Box, 
  Avatar 
} from '@mui/material';
import { getUserRewards, deleteReward } from '../../services/rewardService';
import { useAuth } from '../../contexts/AuthContext';

const RewardDetail = () => {
  const { rewardId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [reward, setReward] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReward = async () => {
      try {
        const fetchedReward = await getUserRewards(rewardId);
        setReward(fetchedReward);
      } catch (err) {
        setError('ご褒美の取得中にエラーが発生しました。');
      } finally {
        setLoading(false);
      }
    };

    fetchReward();
  }, [rewardId]);

  const handleDelete = async () => {
    if (window.confirm('このご褒美を削除してもよろしいですか？')) {
      try {
        await deleteReward(rewardId);
        navigate('/rewards');
      } catch (err) {
        setError('ご褒美の削除中にエラーが発生しました。');
      }
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!reward) return <Typography>ご褒美が見つかりません。</Typography>;

  return (
    <Container>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar sx={{ mr: 2, width: 56, height: 56, bgcolor: 'secondary.main' }}>
          {reward.icon || '🎁'}
        </Avatar>
        <Typography variant="h4">{reward.name}</Typography>
      </Box>
      <Typography variant="body1" paragraph>{reward.description}</Typography>
      <Typography variant="h6">XPコスト: {reward.xpCost} XP</Typography>
      <Box mt={2}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate(`/rewards/${rewardId}/edit`)}
          sx={{ mr: 1 }}
        >
          編集
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          削除
        </Button>
      </Box>
    </Container>
  );
};

export default RewardDetail;