import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Grid,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { claimReward, deleteReward, updateReward } from '../../services/rewardService';
import { Reward } from '../../types/Reward';
import { useAuth } from '../../contexts/AuthContext';
import ProgressBar from '../common/ProgressBar';

interface RewardItemProps {
  reward: Reward;
  onRewardUpdated: () => void;
  currentXP: number;
}

const RewardItem: React.FC<RewardItemProps> = ({ reward, onRewardUpdated, currentXP }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editedReward, setEditedReward] = useState<Reward>(reward);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    setEditedReward(reward);
  }, [reward]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      await updateReward(editedReward.id, editedReward);
      setIsEditing(false);
      onRewardUpdated();
      setSuccess('ご褒美が更新されました');
    } catch (error) {
      console.error('Error updating reward:', error);
      setError('ご褒美の更新に失敗しました');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteReward(editedReward.id);
      setDeleteConfirmOpen(false);
      onRewardUpdated();
      navigate('/rewards');
      setSuccess('ご褒美が削除されました');
    } catch (error) {
      console.error("Error deleting reward: ", error);
      setError('ご褒美の削除に失敗しました');
    }
  };

  const handleClaim = async () => {
    try {
      if (currentUser && !editedReward.claimed) {
        await claimReward(editedReward.id, currentUser.uid);
        onRewardUpdated();
        setSuccess('ご褒美を獲得しました');
      }
    } catch (error) {
      console.error("Error claiming reward: ", error);
      setError('ご褒美の獲得に失敗しました');
    }
  };

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {editedReward.name}
        </Typography>
        <Typography color="text.secondary">
          コスト: {editedReward.xpCost} XP
        </Typography>
        <Typography variant="body2">
          説明: {editedReward.description}
        </Typography>
        <ProgressBar currentXP={currentXP} requiredXP={reward.xpCost} />
        <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
          <Chip
            label={editedReward.claimed ? '獲得済み' : '未獲得'}
            color={editedReward.claimed ? 'secondary' : 'default'}
          />
          <Chip
            label={editedReward.isCustom ? 'カスタム' : 'デフォルト'}
            color={editedReward.isCustom ? 'primary' : 'default'}
          />
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleClaim}
                disabled={editedReward.claimed}
              >
                {editedReward.claimed ? '獲得済み' : 'ご褒美を獲得'}
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleEdit}
              >
                更新
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant="outlined" 
                color="error" 
                onClick={() => setDeleteConfirmOpen(true)}
              >
                削除
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardContent>

      <Dialog open={isEditing} onClose={() => setIsEditing(false)}>
        <DialogTitle>ご褒美の編集</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="名前"
            value={editedReward.name}
            onChange={(e) => setEditedReward({ ...editedReward, name: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="説明"
            value={editedReward.description}
            onChange={(e) => setEditedReward({ ...editedReward, description: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            label="XPコスト"
            type="number"
            value={editedReward.xpCost}
            onChange={(e) => setEditedReward({ ...editedReward, xpCost: parseInt(e.target.value) })}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>キャンセル</Button>
          <Button onClick={handleSave} color="primary">保存</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>ご褒美の削除</DialogTitle>
        <DialogContent>
          <Typography>本当にこのご褒美を削除しますか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>キャンセル</Button>
          <Button onClick={handleDelete} color="error">削除</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess(null)}>
        <Alert onClose={() => setSuccess(null)} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default RewardItem;