import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';

interface UserProfile {
  id: string;
  name: string;
  email: string;
  xp: number;
  // 他のプロフィールフィールドを追加
}

export const getUserProfile = async (userId: string): Promise<UserProfile | null> => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return { id: userSnap.id, ...userSnap.data() } as UserProfile;
  } else {
    return null;
  }
};

export const createUserProfile = async (userId: string, profileData: Omit<UserProfile, 'id'>): Promise<void> => {
  const userRef = doc(db, 'users', userId);
  await setDoc(userRef, { ...profileData, xp: 0 });
};

export const updateUserProfile = async (userId: string, profileData: Partial<UserProfile>): Promise<void> => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, profileData);
};

export const getXPBalance = async (userId: string): Promise<number> => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data().xp || 0;
  } else {
    return 0;
  }
};

export const updateXP = async (userId: string, xpChange: number): Promise<void> => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    xp: increment(xpChange)
  });
};

export const addXP = async (userId: string, amount: number): Promise<void> => {
  await updateXP(userId, amount);
};

export const subtractXP = async (userId: string, amount: number): Promise<void> => {
  await updateXP(userId, -amount);
};

// getUserXP を getXPBalance のエイリアスとして追加
export const getUserXP = getXPBalance;