import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { XPProvider } from './contexts/XPContext';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Sidebar from './components/common/Sidebar';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import Dashboard from './components/dashboard/Dashboard';
import EditProfile from './components/profile/EditProfile';
import BadgeDisplay from './components/profile/BadgeDisplay';
import CreateQuest from './components/quests/CreateQuest';
import EditQuest from './components/quests/EditQuest';
import QuestList from './components/quests/QuestList.tsx';
import QuestItemWrapper from './components/quests/QuestItemWrapper';
import RewardList from './components/rewards/RewardList';
import CreateReward from './components/rewards/CreateReward';
import RewardDetail from './components/rewards/RewardDetail';
import EditReward from './components/rewards/EditReward';
import BottomNav from './components/common/BottomNav';
import HomePage from './components/home/HomePage';
import PublicHomePage from './components/home/PublicHomePage';
import LandingPage from './components/lp/LandingPage'

const theme = createTheme({
  // ... テーマ設定 ...
});

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/home" /> : children;
};

const HomeRedirect = () => {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/home" /> : <Navigate to="/public-home" />;
};

function AppContent() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleQuestCreated = () => {
    console.log("Quest created successfully");
  };

  return (
    <AuthProvider navigate={navigate}>
      <XPProvider>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header onMenuToggle={handleSidebarToggle} />
          <Sidebar open={sidebarOpen} onClose={handleSidebarToggle} />
          <main style={{ flexGrow: 1, padding: '20px' }}>
            <Routes>
              <Route path="/" element={<HomeRedirect />} />
              <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
              <Route path="/signup" element={<PublicRoute><Signup /></PublicRoute>} />
              <Route path="/public-home" element={<PublicRoute><PublicHomePage /></PublicRoute>} />
              <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
              <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/profile/edit" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
              <Route path="/profile/badgedisplay" element={<PrivateRoute><BadgeDisplay /></PrivateRoute>} />
              <Route path="/quests/create" element={<PrivateRoute><CreateQuest onQuestCreated={handleQuestCreated} /></PrivateRoute>} />
              <Route path="/quests/:questId/edit" element={<PrivateRoute><EditQuest /></PrivateRoute>} />
              <Route path="/quests" element={<PrivateRoute><QuestList /></PrivateRoute>} />
              <Route path="/quests/:questId" element={<PrivateRoute><QuestItemWrapper /></PrivateRoute>} />
              <Route path="/rewards" element={<PrivateRoute><RewardList /></PrivateRoute>} />
              <Route path="/rewards/create" element={<PrivateRoute><CreateReward /></PrivateRoute>} />
              <Route path="/rewards/:rewardId" element={<PrivateRoute><RewardDetail /></PrivateRoute>} />
              <Route path="/rewards/:rewardId/edit" element={<PrivateRoute><EditReward /></PrivateRoute>} />
              <Route path="/lp" element={<LandingPage />} />

            </Routes>
            <BottomNav />
          </main>
          <Footer />
        </div>
      </XPProvider>
    </AuthProvider>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;