

// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';  // 追加


const firebaseConfig = {
    apiKey: "AIzaSyATlIcqlPIAqJWxgD2_T3C3gLXy73aZ6x8",
    authDomain: "ludibit-app.firebaseapp.com",
    projectId: "ludibit-app",
    storageBucket: "ludibit-app.appspot.com",
    messagingSenderId: "397987994116",
    appId: "1:397987994116:web:dbd22be716f64af5fcf351",
    measurementId: "G-8EY2ZTG95P"};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);  // 追加

