import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getQuest } from '../../services/questService';
import QuestItem from './QuestItem';
import { CircularProgress, Typography } from '@mui/material';

const QuestItemWrapper = () => {
  const { questId } = useParams();
  const [quest, setQuest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchQuest = async () => {
    try {
      setLoading(true);
      const fetchedQuest = await getQuest(questId);
      setQuest(fetchedQuest);
      setError('');
    } catch (err) {
      setError('クエストの取得に失敗しました');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuest();
  }, [questId]);

  const handleQuestUpdated = () => {
    fetchQuest();
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!quest) return <Typography>クエストが見つかりません</Typography>;

  return <QuestItem quest={quest} onQuestUpdated={handleQuestUpdated} />;
};

export default QuestItemWrapper;