
import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
            <Container maxWidth="lg">
                <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://ludibit.com/">
                 Ludibit
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;