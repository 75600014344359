import React, { useEffect, useState } from 'react';
import { Typography, Button, Box, Container } from '@mui/material';
import { useXP } from '../../contexts/XPContext';

const QuestClearScreen = ({ quest, onClose }) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const { xp, updateXP } = useXP();
  const [earnedXP, setEarnedXP] = useState(0);

  useEffect(() => {
    setEarnedXP(quest.xp);
    updateXP(xp + quest.xp);
    setShowConfetti(true);
  }, [quest, xp, updateXP]);

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'primary.main' }}>
          クエストクリア！
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          {quest.name}
        </Typography>
        <Typography variant="body1" paragraph>
          {quest.description}
        </Typography>
        <Typography variant="h6">
          獲得ポイント: {earnedXP}
        </Typography>
        {showConfetti && <div>🎉</div>} {/* 簡易的な紙吹雪の表現 */}
        <Box mt={4}>
          <Button variant="contained" color="primary" onClick={onClose}>
            OK
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default QuestClearScreen;