import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Container, Paper, Typography, CircularProgress } from '@mui/material';
import XPDisplay from '../common/XPDisplay';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // ユーザー情報の取得
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        setUserData(userDoc.data());
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('データの取得中にエラーが発生しました。');
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>ダッシュボード</Typography>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h5">ようこそ、{userData?.name}さん</Typography>
        <XPDisplay />
      </Paper>
    </Container>
  );
};

export default Dashboard;