// src/services/badgeService.js
import { db } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion, collection } from 'firebase/firestore';

export const getUserBadges = async (userId) => {
  if (!userId) {
    console.error('Invalid userId provided to getUserBadges');
    return [];
  }

  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      console.error('User document does not exist');
      return [];
    }
    
    const badgeIds = userDoc.data().badges || [];
    const badges = await Promise.all(
      badgeIds.map(async (badgeId) => {
        const badgeDocRef = doc(db, 'badges', badgeId);
        const badgeDoc = await getDoc(badgeDocRef);
        if (badgeDoc.exists()) {
          return { id: badgeId, ...badgeDoc.data() };
        } else {
          console.warn(`Badge with id ${badgeId} not found`);
          return null;
        }
      })
    );
    
    return badges.filter(badge => badge !== null);
  } catch (error) {
    console.error('Error fetching user badges:', error);
    return [];
  }
};

export const awardBadge = async (userId, badgeId) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    badges: arrayUnion(badgeId)
  });
  // ここで通知を送る処理を追加することもできます
};

// バッジ獲得条件をチェックする関数の例
export const checkBadgeEligibility = async (userId) => {
  // ユーザーのアクションに基づいてバッジ獲得条件をチェック
  // 条件を満たしていれば awardBadge を呼び出す
};