import { db } from '../firebase';
import { 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs, 
  getDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  Timestamp,
  serverTimestamp,
  arrayUnion
} from 'firebase/firestore';
import { addXP } from './userService';

export interface Quest {
  id?: string;
  name: string;
  description: string;
  type: 'weekly' | 'specific';
  weeklySchedule: string[];
  specificDates: Timestamp[];
  startDate: Timestamp | null;
  endDate: Timestamp | null;
  status: 'active' | 'completed';
  userId: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  completedAt?: Timestamp;
  xp: number;
  category: string;
  deadline?: Timestamp;
  completedDates: Timestamp[];
  lastCompletedDate?: Timestamp;
}

export const createQuest = async (questData: Omit<Quest, 'id'>, userId: string): Promise<string> => {
  try {
    const docRef = await addDoc(collection(db, 'quests'), {
      ...questData,
      userId,
      weeklySchedule: questData.weeklySchedule.map((day: string) => day.toString()),
      createdAt: serverTimestamp(),
      status: 'active',
      completedDates: []
    });
    console.log('Quest created with ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error creating quest:', error);
    throw error;
  }
};

export const getTodayQuests = async (userId: string): Promise<Quest[]> => {
  const today = new Date();
  const dayOfWeek = today.getDay().toString();

  const questsQuery = query(
    collection(db, 'quests'),
    where('userId', '==', userId),
    where('status', '==', 'active')
  );

  const querySnapshot = await getDocs(questsQuery);

  const todayQuests = querySnapshot.docs
    .map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        specificDates: Array.isArray(data.specificDates) ? data.specificDates : [],
        completedDates: Array.isArray(data.completedDates) ? data.completedDates : [],
      } as Quest;
    })
    .filter(quest => {
      if (quest.type === 'weekly') {
        return quest.weeklySchedule.includes(dayOfWeek) &&
               (!quest.startDate || quest.startDate.toDate() <= today) &&
               (!quest.endDate || quest.endDate.toDate() >= today) &&
               !quest.completedDates.some(date => date.toDate().toDateString() === today.toDateString());
      } else if (quest.type === 'specific') {
        return Array.isArray(quest.specificDates) && quest.specificDates.some(date => 
          date instanceof Timestamp && date.toDate().toDateString() === today.toDateString()
        ) && !quest.completedDates.some(date => date.toDate().toDateString() === today.toDateString());
      }
      return false;
    });

  console.log('Today quests:', todayQuests);
  return todayQuests;
};

export const getQuest = async (questId: string): Promise<Quest> => {
  try {
    const questDoc = await getDoc(doc(db, 'quests', questId));
    if (questDoc.exists()) {
      const data = questDoc.data();
      return {
        id: questDoc.id,
        ...data,
        startDate: data.startDate instanceof Timestamp ? data.startDate : null,
        endDate: data.endDate instanceof Timestamp ? data.endDate : null,
        completedDates: Array.isArray(data.completedDates) ? data.completedDates : [],
      } as Quest;
    } else {
      throw new Error('クエストが見つかりません。');
    }
  } catch (error) {
    console.error('Error getting quest:', error);
    throw error;
  }
};

export const updateQuest = async (questId: string, updatedData: Partial<Quest>): Promise<void> => {
  try {
    const questRef = doc(db, 'quests', questId);
    const dataToUpdate = {
      ...updatedData,
      specificDates: updatedData.specificDates && updatedData.specificDates.length > 0
        ? updatedData.specificDates.map(date => date instanceof Date ? Timestamp.fromDate(date) : date)
        : [],
      startDate: updatedData.startDate instanceof Date ? Timestamp.fromDate(updatedData.startDate) : updatedData.startDate,
      endDate: updatedData.endDate instanceof Date ? Timestamp.fromDate(updatedData.endDate) : updatedData.endDate,
      updatedAt: Timestamp.now()
    };
    await updateDoc(questRef, dataToUpdate);
    console.log('Quest updated successfully');
  } catch (error) {
    console.error('Error updating quest:', error);
    throw error;
  }
};

export const deleteQuest = async (questId: string): Promise<void> => {
  const docRef = doc(db, 'quests', questId);
  await deleteDoc(docRef);
};

export const completeQuest = async (questId: string, userId: string): Promise<void> => {
  const docRef = doc(db, 'quests', questId);
  const today = Timestamp.now();
  
  const questDoc = await getDoc(docRef);
  const quest = questDoc.data() as Quest;

  await updateDoc(docRef, { 
    completedDates: arrayUnion(today),
    lastCompletedDate: today
  });

  // XPを加算
  await addXP(userId, quest.xp);
  
  if (quest.type === 'specific' || (quest.endDate && quest.endDate.toDate() <= today.toDate())) {
    await updateDoc(docRef, { status: 'completed' });
  }
};

export const getUserQuests = async (userId: string): Promise<Quest[]> => {
  const q = query(collection(db, 'quests'), where('userId', '==', userId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ 
    id: doc.id, 
    ...doc.data(),
    completedDates: Array.isArray(doc.data().completedDates) ? doc.data().completedDates : [],
  } as Quest));
};